import { Deferred } from '../helpers'
import appContext from '../viewer-app-module/DataBindingAppContext'
import DataStore from '../data/DataStore'
import { getReferencedCollectionIds } from '../data/utils'
import { ServerError, ServerValidationError, AppError, Trace } from '../logger'
import { ROUTER_DATASET } from '@wix/wix-data-client-common/src/datasetTypes'

export default class DataProvider {
  constructor() {
    const { logger, dataFetcher } = appContext
    this.#dataFetcher = dataFetcher
    this.#logger = logger
    this.#dataStore = new DataStore()
    this.#dataFetchingBulk = {}
    this.#bulkDataFetching = Promise.resolve()
  }

  createBulkRequest(datasetFetchConfigs) {
    this.#dataFetchingBulk = datasetFetchConfigs.reduce(
      (acc, { id, refresh }) =>
        refresh || !this.#dataStore.hasDataset(id)
          ? { ...acc, [id]: new Deferred() }
          : acc,
      {},
    )

    const dataRequestingDatasets = Object.entries(this.#dataFetchingBulk).map(
      ([, { promise }]) => promise,
    )

    this.#bulkDataFetching = this.#waitForDataFetched(
      dataRequestingDatasets,
    ).then(() => (this.#dataFetchingBulk = {}))
  }

  async getDataFromBulk({
    datasetId,
    collectionId,
    filter,
    sort,
    length,
    includes,
    uniqueFieldValues,
  }) {
    if (this.#dataFetchingBulk[datasetId]) {
      // TODO: reject and return cache data if we have it
      this.#dataFetchingBulk[datasetId].resolve({
        datasetId,
        collectionId,
        filter,
        sort,
        offset: 0,
        length,
        includes,
        uniqueFieldValues,
      })

      await this.#bulkDataFetching
    }

    return (
      this.#dataStore.getData({
        datasetId,
        collectionId,
        includes,
      }) ||
      this.getData({
        collectionId,
        filter,
        sort,
        offset: 0,
        length,
        includes,
        uniqueFieldValues,
      })
    )
  }

  async getData(datasetConfig) {
    const {
      collectionId,
      filter,
      sort,
      offset,
      length,
      includes,
      uniqueFieldValues,
    } = datasetConfig
    const uniqueFieldValuesToFetch = uniqueFieldValues
      ? uniqueFieldValues.filter(
          fieldKey => !this.getUniqueFieldValues({ collectionId, fieldKey }),
        )
      : null
    const data = await this.#dataFetcher
      .fetchData({
        collectionId,
        filter,
        sort,
        offset,
        length,
        includes,
        uniqueFieldValues: uniqueFieldValuesToFetch,
      })
      .catch(createErrorHandler('Data fetching failed', { datasetConfig }))

    await this.#schemasLoading
    this.#dataStore.updateCollectionData({ collectionId, data })

    return data
  }

  async remove({ collectionId, recordId }) {
    return this.#dataFetcher
      .remove({
        collectionId,
        recordId,
      })
      .catch(
        createErrorHandler('Record removing failed', {
          collectionId,
          recordId,
        }),
      )
  }

  async save({ collectionId, record, includeReferences }) {
    return this.#dataFetcher
      .save({
        collectionId,
        record,
        includeReferences,
      })
      .catch(
        createErrorHandler('Record saving failed', {
          collectionId,
          record,
          includeReferences,
        }),
      )
  }

  async getSibling(config) {
    return await this.#dataFetcher.getSibling(config)
  }

  async loadSchemas(collectionIds) {
    const cachedSchemas = this.#dataStore.getSchemas()
    const notCachedCollectionIds = collectionIds.filter(
      collectionId => !cachedSchemas[collectionId],
    )
    this.#schemasLoading = notCachedCollectionIds.length
      ? this.#dataFetcher.fetchSchemas(notCachedCollectionIds).catch(
          createErrorHandler('Schema fetching failed', {
            collectionIds: notCachedCollectionIds,
          }),
        )
      : Promise.resolve({})
    const fetchedSchemas = await this.#schemasLoading

    //TODO: removed collection doesn't get its schema
    //TODO: viewer runs us for the first time without routerReturnedData!!

    this.#dataStore.updateSchemas({ ...cachedSchemas, ...fetchedSchemas })
    return this.#dataStore.getSchemas()
  }

  async setSchemas(schemas) {
    this.#dataStore.updateSchemas(schemas)
  }

  getSchemas() {
    return this.#dataStore.getSchemas()
  }

  getRecord({ collectionId, recordId, includes }) {
    return this.#dataStore.getRecord({
      collectionId,
      recordId,
      includes,
    })
  }

  getSchema(collectionId) {
    return this.#dataStore.getSchema(collectionId)
  }

  hasSchema(collectionId) {
    return Boolean(this.getSchema(collectionId))
  }

  getReferencedSchemas(collectionId) {
    //TODO: getFieldType can be called by userInput resolver with dataset of removed collection
    // for some unknown reason. should be changed to something like
    // if there is no colleciton to filter by, don't filter at all!
    const schema = this.getSchema(collectionId)
    const schemas = this.#dataStore.getSchemas()

    return getReferencedCollectionIds(schema).reduce(
      (acc, collectionId) => ({
        ...acc,
        [collectionId]: schemas[collectionId],
      }),
      {},
    )
  }

  setCollectionData({ collectionId, data }) {
    if (data) {
      this.#dataStore.updateCollectionData({ collectionId, data })
    }
  }

  setStore(store) {
    if (store) {
      this.#dataStore.updateStore(store)
    }
  }

  setStaticStore(store) {
    const {
      recordsByCollectionId,
      recordInfosInDatasetOrder,
      uniqueFieldValuesByCollectionId,
    } = store

    const { datasetId: routerDatasetId } = this.#datasetConfigs.find(
      ({ type }) => type === ROUTER_DATASET,
    )
    const recordInfosByDatasetId = {
      [routerDatasetId]: recordInfosInDatasetOrder[0],
    }

    this.#dataStore.updateStore({
      recordsByCollectionId,
      recordInfosByDatasetId,
      uniqueFieldValuesByCollectionId,
    })
  }

  getStore() {
    return this.#dataStore.getStore()
  }

  setUniqueFieldValues({ collectionId, fieldKey, data }) {
    return this.#dataStore.setUniqueFieldValues({
      collectionId,
      fieldKey,
      data,
    })
  }

  getUniqueFieldValues({ collectionId, fieldKey }) {
    return this.#dataStore.getUniqueFieldValues({ collectionId, fieldKey })
  }

  createSimpleFilter(key, value) {
    return this.#dataFetcher.createSimpleFilter(key, value)
  }

  setDatasetConfigs(datasetConfigs) {
    this.#datasetConfigs = datasetConfigs
  }

  #logger
  #dataFetcher
  #dataStore
  #dataFetchingBulk
  #bulkDataFetching
  #schemasLoading
  #datasetConfigs

  async #fetchBulkData(datasetConfigs) {
    try {
      const {
        recordsByCollectionId,
        //TODO: come up with better name for recordsInfoByDataset,
        //since it is just an ordered array acording to our internal dataset config.
        //server knows nothing about dataset id.
        recordInfosInDatasetOrder,
        uniqueFieldValuesByCollection,
      } = await this.#logger.log(
        new Trace('dataset/fetchPrimaryInitialData', () =>
          this.#dataFetcher.fetchBulkData(datasetConfigs),
        ),
      )

      const recordInfosByDatasetId = recordInfosInDatasetOrder.reduce(
        (acc, { itemIds = [], totalCount = 0, error }, index) => {
          const datasetConfig = datasetConfigs[index]
          if (error) {
            this.#logger.log(
              new DataFetcherError(
                'Data fetching in a bulk failed for one of the datasets',
                {
                  cause: error,
                  extra: { datasetConfig },
                },
              ),
            )
          }

          acc[datasetConfig.datasetId] = { itemIds, totalCount }

          return acc
        },
        {},
      )

      return {
        recordsByCollectionId,
        recordInfosByDatasetId,
        uniqueFieldValuesByCollection,
      }
    } catch (e) {
      throw new AppError('Bulk data fetching failed', {
        cause: e,
        extra: { datasetConfigs },
      })
    }
  }

  async #waitForDataFetched(dataRequestingDatasets) {
    if (dataRequestingDatasets.length) {
      const datasetConfigs = await Promise.all(dataRequestingDatasets)
      const data = await this.#fetchBulkData(datasetConfigs)
      await this.#schemasLoading

      this.#dataStore.updateStore(data)
    }
  }
}

class DataFetcherError {
  constructor(message, { cause, ...rest }) {
    const TheError = ServerValidationError.codes.includes(cause.code)
      ? ServerValidationError
      : ServerError

    return new TheError(message, { ...rest, cause, code: cause.code })
  }
}

const createErrorHandler = (message, extra) => cause => {
  throw new DataFetcherError(message, { cause, extra })
}
